<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <g clip-path="url(#clip0_1033_455)">
            <path
                d="M4.16667 12.5003C3.3901 12.5003 3.00182 12.5003 2.69553 12.3735C2.28715 12.2043 1.9627 11.8798 1.79354 11.4715C1.66667 11.1652 1.66667 10.7769 1.66667 10.0003V4.33366C1.66667 3.40024 1.66667 2.93353 1.84833 2.57701C2.00812 2.2634 2.26308 2.00844 2.57669 1.84865C2.93321 1.66699 3.39992 1.66699 4.33334 1.66699H10C10.7766 1.66699 11.1649 1.66699 11.4711 1.79386C11.8795 1.96302 12.204 2.28747 12.3731 2.69585C12.5 3.00214 12.5 3.39042 12.5 4.16699M10.1667 18.3337H15.6667C16.6001 18.3337 17.0668 18.3337 17.4233 18.152C17.7369 17.9922 17.9919 17.7372 18.1517 17.4236C18.3333 17.0671 18.3333 16.6004 18.3333 15.667V10.167C18.3333 9.23357 18.3333 8.76686 18.1517 8.41034C17.9919 8.09674 17.7369 7.84177 17.4233 7.68198C17.0668 7.50033 16.6001 7.50033 15.6667 7.50033H10.1667C9.23325 7.50033 8.76654 7.50033 8.41002 7.68198C8.09642 7.84177 7.84145 8.09674 7.68166 8.41034C7.50001 8.76686 7.50001 9.23357 7.50001 10.167V15.667C7.50001 16.6004 7.50001 17.0671 7.68166 17.4236C7.84145 17.7372 8.09642 17.9922 8.41002 18.152C8.76654 18.3337 9.23325 18.3337 10.1667 18.3337Z"
                stroke="#474747" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_1033_455">
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script setup lang="ts"></script>

<style></style>
